@import "../../../../global/styles";

@mixin base {
  color: white;
  border-radius: 3px;
  font-size: 13px;
  padding: 5px 10px;
  margin-left: 10px;
  line-height: 12px;
  position: relative;
  cursor: pointer;
  top: -2px;
  
  &:hover, &:global(.hover) {
    color: white;
  }

  > span:first-child {
    margin-right: 5px;
  }
}

.metrics {
  background: $primary;
  @include base;
  
  &:hover, &:global(.hover) {
    color: white;
  }
  
  .delimiter{
    padding: 0 5px;
  }
}

.warnings {
  background: $warning;
  @include base;
}
