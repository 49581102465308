@import '../../global/styles.scss';

.announcement {
  color: #fff;
  align-self: center;
  text-align: center;
  line-height: 1.3em;

  a:not(:global(.color-warning)) {
    color: #fff;
    text-decoration: underline;
  }
}
