.row {
  display: flex;

  > div {
    &:first-child {
      flex: 2;
    }

    &:last-child {
      flex: 3;
      margin-left: 30px;
    }
  }
}

.cluster-role-select {
  flex: 1;

  > :global(.ant-select) {
    width: 100%;
  }
}

.checkbox {
  display: flex;
  padding: 2.5px 0;
  align-items: center;
}