@import '../../../../global/styles';

.select {
  :global(.ant-select-selection-item) {
    .option {
      color: white !important;
    }
  }
}

.access-rules {
  .access-rule-header {
    display: flex;
    margin-top: 10px;
    
    > div {
      flex: 1;
      @include text-small-bold;
      
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  
  .access-rule {
    display: flex;
    margin-bottom: 10px;
    
    > div:first-child {
      margin-right: 10px;
    }

    .select {
      flex: 1;

      > :global(.ant-select) {
        width: 100%;
      }
    }
  }
}