@import '../../global/styles.scss';

.multiple {
  :global(.ant-select-selection-item) {
    background: $text-light-80 !important;
    color: white;

    * {
      color: white;
    }
  }
}

.tags {
  width: 100%;

  > :global(.ant-select) {
    width: 100%;
  }
}

.hidden {
  display: none;
}

.changed {
  position: relative;
  > :global(.ant-select) {
    > :global(.ant-select-selector) {
      border: 1px solid $warning;
    }
  }

  > .text {
    @include text-small();
    color: $warning;

    cursor: pointer;
    position: absolute;
    right: 0;
    top: -1.8em;
  }
}