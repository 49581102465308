@import "../../global/styles";

.labeled-value {
  > .label {
    @include text-small;
    padding-bottom: 0.3em;
    color: $text-dark-90;
  }

  > .value {
    @include text;
  }
}