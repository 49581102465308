@import "../../global/styles";

.auth-layout {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-image: url("../../images/auth-background.jpeg");

  .container {
    @include shadow-dark-10;

    display: flex;
    align-self: baseline;
    flex-direction: column;
    align-items: center;
    width: 400px;
    padding: 20px 40px;
    background: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .logo {
      padding-top: 20px;
      padding-bottom: 15px;

      .logo-svg {
        > svg {
          width: 201px;
          height: 100px;
        }
      }
    }

    .header {
      padding: 6px 0;

      .title {
        @include h3;
      }

      .description {
        @include text;
        text-align: center;
      }
    }
  }
}