@import '../../global/styles';

.code {
  background: $bg-dark-80;
  color: $text-light-90;
  border-radius: 2px;
  display: flex;
  padding: 8px;

  .code-icon {
    vertical-align: middle;
    color: $link-hover;
    margin-top: 5px;

    > svg {
      width: 16px;
      height: 16px;
    }
  }

  > .code-text {
    padding-left: 6px;
    font-family: 'Source Code Pro', monospace;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.8em;
  }
}