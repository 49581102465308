@import '../../global/styles.scss';

.link {
  @include text-small-bold;
  color: $warning;

  &.clickable {
    cursor: pointer;
  }
}
