@import "../../../../../global/styles";

.warning-column {
  color: $warning;
}

.events-popover {
  max-width: 500px;

  .event-header {
    display: flex;
    padding: 3px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 600;

    > div {
      &:nth-child(1) {
        flex-basis: 100px;
        flex-grow: 0;
        flex-shrink: 0;
      }

      &:nth-child(2) {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
      }
    }
  }

  .event-items {
    > div {
      display: flex;
      font-size: 12px;
      padding: 3px;

      > div {
        &:nth-child(1) {
          font-size: 11px;
          flex-basis: 100px;
          flex-grow: 0;
          flex-shrink: 0;
        }

        &:nth-child(2) {
          flex-grow: 1;
        }
      }

      background-color: $bg-light-80;
      &:nth-child(odd){
        background-color: $bg-light-70;
      }
    }
  }
}

.type-warning {
  color: $warning;
  white-space: nowrap;
  
  > span {
    padding-right: 5px;
  }
}