@import "../../global/styles";

.owner {
  padding: 8px 7px;
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;

  > :global(.anticon) {
    font-size: 12px;
    margin-right: 7px;
  }

  &.small {
    @include text-small;
    color: $text-dark-90;
    padding: 0;
  }

  &.bold {
    @include text-small-bold;
    color: $text-dark-80;
  }
  
  &.clickable {
    @include clickable-name;
  }

  &.dark {
    @include text-small-bold;
    color: white;
    background: $text-dark-80;
    border-radius: 2px;

    > :global(.anticon) {
      > svg {
        path {
          fill: white !important;
        }
      }
    }
  }
}

