@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700');

$sider-background: #F8FBFF;
$header-background: #263544;
$error-message: #AA0000;

// text
$info: #077eec;
$link-hover: #4BA9FF;
$logo: #00BDFF;

// figma
$bg-dark-80: #263544;
$bg-dark-70: #3C5064;
$bg-light-80: #F8FBFF;
$bg-light-70: #EFF3F9;
$bg-light-60: #e4ecf9;
$bg-light-50: #E1E3E8;
$text-dark: #263544;
$text-dark-90: #465E75;
$text-dark-80: #6B88A6;
$text-light: #EFF3F9;
$text-light-90: #B9C7DB;
$text-light-80: #8A9BB5;

$info: #077EEC;
$primary: #077EEC;
$success: #3AAF00;
$loading: #077EEC;
$error: #C21B1B;
$warning: #ED7200;

$impersonation-background: #de782d;
$impersonation-link: #b33027;

// color classes for convenience
.color-bg-dark-80 {
  color: $bg-dark-80 !important;
}
.color-bg-light-80 {
  color: $bg-light-80 !important;
}
.color-text-dark {
  color: $text-dark !important;
}
.color-text-dark-90 {
  color: $text-dark-90 !important;
}
.color-text-dark-80 {
  color: $text-dark-80 !important;
}
.color-info {
  color: $info !important;
}
.color-primary {
  color: $primary !important;
}
.color-success {
  color: $success !important;
}
.color-loading {
  color: $loading !important;
}
.color-error {
  color: $error !important;
}
.color-warning {
  color: $warning !important;
}

.drawer-row {
  display: flex;

  > div {
    &:first-child {
      flex: 1;
    }

    &:last-child {
      flex: 1;
      margin-left: 30px;
    }
  }
}

@mixin shadow-dark-10 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@mixin clickable-name {
  @include text-bold();
  color: $primary;
  cursor: pointer;
}

@mixin clickable-name-normal {
  @include text();
  color: $primary;
  cursor: pointer;
}

@mixin h4 {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5em;
  color: $text-dark-90;
}

h4 {
  @include h4;
  margin: 0;
}

@mixin h3 {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 1.6em;
  color: $text-dark-90;
}

@mixin text {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4em;
  color: $text-dark-90;
}

.text {
  @include text;
}

@mixin text-large {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5em;
  color: $text-dark-90;
}

.text-large {
  @include text-large;
}

@mixin text-large-bold {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5em;
  color: $text-dark-90;
}

.text-large-bold {
  @include text-large-bold;
}

@mixin text-bold {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5em;
  color: $text-dark-90;
}

.text-bold {
  @include text-bold;
}

@mixin text-bolder {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5em;
  color: $text-dark-90;
}

.text-bolder {
  @include text-bolder;
}

@mixin text-small {
  font-size: 12px;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5em;
  color: $text-dark-90;
}

.text-small {
  @include text-small;
}

@mixin text-small-bold {
  font-size: 12px;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5em;
  color: $text-dark-90;
}

.text-small-bold {
  @include text-small-bold;
}

@mixin text-small-bolder {
  font-size: 12px;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 1.5em;
  color: $text-dark-90;
}

@mixin ant-override-color-button($background, $hover, $focus) {
  background-color: $background;
  border-color: $background;

  &:hover, &.hover {
    background-color: $hover;
    border-color: $hover;
  }

  &:focus {
    background-color: $focus;
    border-color: $focus;
  }
}

@mixin ant-icon-button($background, $color: $text-dark-80) {
  cursor: pointer;
  color: $color;
  font-size: 16px;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  border-radius: 3px;
  transition: background-color .3s, color .3s;

  &:hover, &.hover {
    color: white;
    background: $background;

    > svg {
      path {
        fill: white !important;
      }
    }
  }

  > svg {
    height: 32px;

    path {
      fill: $color !important;
    }
  }
}

@mixin auth-button-wrapper {
  margin: 15px 0 0 0;
  padding: 8px 0;
  display: flex;
  justify-content: center;
}

.ant-message {
  z-index: 5000;
}

.row {
  display: flex;

  > div {
    flex: 1;

    &:first-child {
      margin-right: 15px;
    }

    &:last-child {
      margin-left: 15px;
    }
  }
}

.ant-table {
  tbody > tr {
    .hover-link {
      color: $text-dark-90;
    }

    &:hover, &.hover {
      .hover-link {
        @include clickable-name-normal;
      }
    }
  }
}

.ant-table-content {
  overflow-x: auto;
}

body .clickable-link {
  @include clickable-name;
}

.blue-btn {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.blue-btn-batch {
  @include ant-icon-button(transparent, white);
}

.actions-delete-btn {
  @include ant-icon-button($error);
  margin: -8px 0;
}

.actions-delete-btn-batch {
  @include ant-icon-button(transparent, white);
}

.ant-typography {
  color: unset;
}

.actions-wrapper {
  min-width: 100px !important;
}

.hidden {
  display: none;
}

.ant-drawer-header-title {
    flex-direction: row-reverse;

    > .ant-drawer-close {
        margin-right: 0;
    }
}
