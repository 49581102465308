@import "../../../../global/styles";

.admin-wrapper {
  padding: 16px 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.not-found {
  padding: 10px;
  color: $warning;
}

.actions {
  min-width: 100px !important;
}

.clickable {
  @include clickable-name;
}
.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}


.cluster-error {
  color: $error;
  cursor: pointer;

  > :global(.anticon) {
    margin-right: 7px;
  }
}

.setting-batch {
  @include ant-icon-button(transparent, white);
}

.delete {
  @include ant-icon-button($error);
  margin: -8px 0;
}

.delete-batch {
  @include ant-icon-button(transparent, white);
}

.reset-password {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.table {
  tbody > tr {
    .namespace {
      color: $text-dark-90;
    }

    &:hover, &:global(.hover) {
      .namespace {
        @include clickable-name-normal;
      }
    }
  }
}
