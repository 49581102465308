@import "../../../global/styles";

.small-text {
  @include text-small;
  color: $text-dark-80;
}

.finish-wrapper {
  @include auth-button-wrapper;
}

.legal-hint {
  @include text;
  padding-top: 10px;
  color: $text-dark-80;
  text-align: center;
}
