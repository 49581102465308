@import "../../global/styles";

.progress {
  display: flex;

  > :global(.ant-progress-outer) {
    order: 2;

    margin-left: 8px;
  }

  > :global(.ant-progress-text) {
    @include text-bold();
    color: $info;

    order: 1;
    margin-left: 0;
    width: 2.5em;
    text-align: center;
  }
}