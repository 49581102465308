@import "../../global/styles";

.description {
  @include text-small;
  color: $text-dark-80;

  margin-top: 4px;
  margin-bottom: 4px;
  
  &.description-table {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.description-column {
  ul, ol {
      padding-left: 20px;
  }

  p {
      margin-top: 5px;
      margin-bottom: 5px;
  }

  blockquote {
    border-left: 5px solid $warning;
    background: rgba($warning, 0.2);

    padding: 5px;
    padding-left: 10px;

    > p {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  pre {
    font-size: 11px;
    background: $bg-dark-80;
    color: $text-light-90;
    border-radius: 0px;
    padding: 8px;
    outline: 3px solid $bg-dark-80;

    > code {
      font-size: 11px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $bg-dark-80;
    }

    &::-webkit-scrollbar-thumb {
      background: $text-light-80;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {

    }
  }

  code, p code, table code, blockquote code {
    background: $bg-light-50;
    padding: 1px 2px;
    font-size: 11px;
  }

  table {
    table-layout: fixed;
    width: 100%;

    tr:nth-child(even) {
      background: #ECEFFF;
    }

    tr:nth-child(odd) {}
  }
}