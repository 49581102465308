@import "../../global/styles";

.dock {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
    
    :global(.ant-tabs-nav) {
        margin: 0 0 10px 0 !important;
    }
    
    :global(.ant-tabs-nav-wrap) {
        height: 31px !important;
    }
    
    :global(.ant-tabs-tab) {
        font-size: 12px !important;
        padding: 4px 8px !important;
        
        :global(.anticon) {
            margin-right: 5px;
        }
        
        :global(.ant-tabs-tab-remove) {
            margin-right: -8px;
            margin-left: 0;
        }
    }
}

.top {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-y: auto;
    min-height: 0;
}

.divider {
    min-height: 0;
    align-items: center;
    border-top: 4px solid #f0f0f0;
    cursor: row-resize;
    height: 0px;
    flex: 0 0 auto;
    transition: border-top-color .25s;
}

.divider:hover, .divider-dragging {
    @extend .divider;
    transition-delay: .25s;
    border-top: 4px solid #1890ff;
}

.bottom {
    min-height: 0;
    overflow: auto;
    box-sizing: border-box;
    
    :global(.ant-tabs) {
        height: 100%
    }
}
