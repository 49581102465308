@import '../../global/styles';

.checkbox {
  color: $text-dark-90;
}

.changed {
  position: relative;
  > .checkbox {
    outline: 1px solid $warning;
    padding-left: 8px;
    left: -8px;
  }

  > .text {
    @include text-small();
    color: $warning;

    cursor: pointer;
    margin-left: 1px;
  }
}