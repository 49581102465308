@import '../../global/styles';

.changed {
  position: relative;
  > .textarea {
    border: 1px solid $warning;
  }

  > .text {
    @include text-small();
    color: $warning;

    cursor: pointer;
    position: absolute;
    right: 0;
    margin-top: -22px;
  }
}

body .textarea {
  min-height: 98px;

  &[readonly] {
    background: $bg-light-70;
    color: $text-dark;
  }
}