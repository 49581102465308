@import "../../../global/styles";

.admin-wrapper {
  padding: 16px 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.config-wrapper {
  max-width: 100%;
}

.config {
  display: flex;
}

.config-editor {
  max-width: 450px;
}

.reference-wrapper {
  margin-left: 10px;
}