@import '../../../../../global/styles';

.target-namespace-switcher {
  display: flex;
  margin-top: 10px;

  .template {
    width: 180px;
    margin-right: 10px;
    background: $bg-light-70;
    line-height: 30px;
    border-radius: 2px;
    border: 1px solid $text-light-90;

    > span {
      padding: 0 7px;
    }
  }

  .cluster-roles-select {
    flex: 1;

    > :global(.ant-select) {
      width: 100%;
    }
  }
}