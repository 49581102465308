@import "../../../global/styles";

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.table {
  tbody > tr {
    .cluster {
      color: $text-dark-90;
    }

    &:hover, &:global(.hover) {
      .cluster {
        @include clickable-name-normal;
      }
    }
  }
}