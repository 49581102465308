.apps-list {
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.alert {
  max-width: 800px;
}