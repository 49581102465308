@import "../../../global/styles";

.title {
  @include text();
  color: $primary;
  cursor: pointer;
  margin-top: 10px;

  > :global(.anticon) {
    margin-right: 6px;

    > svg {
      width: 16px;
      height: 16px;
    }
  }
}

.hidden {
  display: none;
}

.content {
  position: relative;
  padding-bottom: 15px;
  margin-left: 7px;
  padding-left: 15px;
  border-left: 1px solid $bg-light-60;

  &:not(:last-child) {
    margin-bottom: 20px;

    &::after {
        content: " ";
        display: block;
        width: 20px;
        height: 1px;
        position: absolute;
        margin-left: -15px;
        bottom: 0;
        background: $bg-light-60;
    }
  }
}
