@import "../../../../../global/styles";

.alert {
  max-width: 800px;
}

.cluster-subdomain {
  display: flex;
  align-items: center;

  :global(.ant-input-group) {
    > span:first-child {
      @include text-bolder;
    }

    > span:last-child {
      @include text-bold;
    }
  }

  > :global(.anticon) {
    padding: 20px 10px 0;
  }

  > button {
    margin: 20px 0 0 10px;
  }
}