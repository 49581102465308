@import '../../global/styles.scss';

.popover {
  > :global(.ant-popover-content) {
    > :global(.ant-popover-arrow) {
      border-right-color: $primary;
      border-bottom-color: $primary;

      :global(.ant-popover-arrow-content) {
        background: $primary;
      }
      :global(.ant-popover-arrow-content)::before {
        background: $primary;
      }
    }

    > :global(.ant-popover-inner) {
      background: $primary;

      :global(.ant-popover-inner-content) {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        max-width: 64px;
        
        > * {
          flex: 0 0 32px;
        }
      }
    }
  }
}

.table {
  @include shadow-dark-10();
  margin-bottom: 20px;

  .actions {
    min-width: 80px;
    white-space: nowrap;
    position: relative;

    > .actions-icon {
      position: absolute;
      display: flex;
      align-items: center;
      padding-left: 15px;
      color: $primary;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    > .actions-content {
      visibility: hidden;
    }
  }

  thead > tr > th {
    @include text-small-bold();
    color: $text-dark-80;
    transition: unset !important;
    white-space: nowrap;

    border-bottom: 1px $text-light-90 solid !important;
    padding: 14px 16px 16px;
    background: $bg-light-80;
    
    &::before {
      display: none;
    }
    
    :global(.ant-table-column-sorters) {
      justify-content: flex-start;
      
      > span:first-child {
        flex: unset;
        padding-right: 10px;
      }
    }

    :global(.ant-table-filter-column) {
      justify-content: flex-start;
      
      > span:first-child {
        flex: unset;
      }
    }
  }

  tbody > tr {
    &:hover, &:global(.hover) {
      > :global(td.ant-table-cell) {
        background: $bg-light-70;
      }

      .actions {
        > .actions-icon {
          display: none;
        }

        > .actions-content {
          visibility: visible;
        }
      }
    }

    > :global(td.ant-table-cell) {
      @include text();

      border-bottom: 1px $text-light solid !important;
      padding: 17px 16px;
    }
  }

  :global(.ant-pagination) {
    margin-right: 15px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    position: relative;

    .left {
      display: flex;
      align-items: center;

      > :global(.ant-input-search) {
        width: 196px;
      }

      .reload {
        margin-left: 13px;
      }

      .total {
        margin-left: 13px;
      }

      .selected-number {
        margin-left: 5px;
      }

      .bold {
        font-weight: 600;
      }
    }

    .popover-marker {
      position: absolute;
      bottom: -35px;
      left: 8px;
      width: 1px;
      height: 1px;
      background: red;
      z-index: -1;
    }
  }
}
