@import '../../../global/styles';

.step-3 {
  .bold {
    font-weight: 700;
  }

  .please-wait {
    @include text;
    padding-top: 10px;
    color: $text-dark-80;
  }

  .blue-text {
    color: $info;
    padding-top: 14px;
  }
}