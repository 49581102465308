@import "../../global/styles";

.popup {
  .text {
    @include text-bold;
    color: $text-dark-90;

    margin-bottom: 10px;
  }

  .red-text {
    @include text-bold;
    color: $error;

    margin-top: 10px;
  }
}