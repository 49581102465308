@import "../../../../../global/styles";

.clickable {
  @include clickable-name;
}

.link {
  color: $text-dark-90;
}

.failed {
  color: $error;
  cursor: pointer;
}

.outdated {
  color: $warning;
  cursor: pointer;
}

.templates {
  > span {
    background: $primary;
    color: white;
    border-radius: 2px;
    padding: 0 6px;
    height: 26px;
    display: inline-block;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 10px;
  }
}

.actions {
  min-width: 100px !important;
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.setting-batch {
  @include ant-icon-button(transparent, white);
}

.delete {
  @include ant-icon-button($error);
  margin: -8px 0;
}

.delete-batch {
  @include ant-icon-button(transparent, white);
}

.table {
  tbody > tr {
    &:hover, &:global(.hover) {
      .hover-clickable {
        @include clickable-name-normal;
      }
    }
  }
}