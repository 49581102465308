.toggle-column {
  margin-left: -16px;

  > :global(.anticon) {
    font-size: 16px;
    cursor: pointer;
    position: relative;
    top: 2px;
    left: -3px;

    &:hover, &:global(.hover) {
      color: black;
    }
  }
}