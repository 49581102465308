@import '../../global/styles';

.editor {
  &.changed {
    position: relative;
    > div {
      outline: 1px solid $warning;
    }

    > .text {
      @include text-small();
      color: $warning;

      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      margin-top: -22px;
    }
  }

  :global(.ace_placeholder) {
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
    left: -5px;
    transform: unset;
  }
}
