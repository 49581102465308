@import "../../../../global/styles";

.terminal {
  height: 450px;
}

.dropdown {
  z-index: 99999;
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.select-wrapper {
  display: flex;
  align-items: center;

  > span:first-child {
    margin-right: 10px;
  }
}

.header {
  display: flex;
  justify-content: space-between;

  margin-bottom: 15px;
}

.hoverable {
  @include clickable-name;
}