@import "../../global/styles";

.empty-wrapper {
  padding: 16px 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.empty {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  > svg {
    font-size: 82px;
  }

  h4 {
    margin: 5px 0;
  }

  .connect-cluster {
    margin: 25px 0;
  }
}

.cluster {
  @include clickable-name;
}

.cluster-error {
  color: $error;

  > :global(.anticon) {
    margin-right: 7px;
  }
}

.actions {
  min-width: 100px !important;
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.setting-batch {
  @include ant-icon-button(transparent, white);
}

.delete {
  @include ant-icon-button($error);
  margin: -8px 0;
}

.delete-batch {
  @include ant-icon-button(transparent, white);
}

.table {
  tbody > tr {
    &:hover, &:global(.hover) {
      .progress {
        :global(.ant-progress-inner) {
          background-color: white;
        }
      }
    }
  }
}