@import "../../../global/styles";

.big-info-text {
  display: flex;
  justify-content: center;
  @include text-large;
  margin: 20px 0;
}

.app-name {
  display: flex;
  justify-content: center;
  @include h3;
}

.info-text {
  display: flex;
  justify-content: center;
  @include text-small-bold;
  color: $warning;
  margin-top: 10px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}