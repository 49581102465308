@import '../../global/styles.scss';

.apply-manifests-wrapper {
  display: flex;
  align-items: center;
  
  .favourite-route-select {
    margin-right: 10px;
    
    :global(.ant-select) {
      font-size: 12px;
      width: 300px;
      
      :global(.ant-select-selector) {
        height: 28px;
      }
      
      :global(.ant-select-selection-search-input) {
        height: 26px;
      }
    }
  }
}

.breadcrumb-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .apply-manifests {
    @include text-small;
    color: white;
    line-height: 12px;
    padding: 0 8px;
    height: 26px;
  }
}

.impersonation-banner {
  @include text-bold;
  background-color: $impersonation-background;
  color: white;
  padding: 6px 10px;
  display: flex;
  letter-spacing: .4px;
  justify-content: flex-end;
  align-content: center;
  
  .impersonation-icon {
    position: relative;
    top: 2px;
    padding-right: 5px;
    font-size: 22px;
  }
  
  .impersonation-name {
    text-decoration: underline;
    padding-left: 1px;
  }
  
  .impersonation-link {
    background-color: $impersonation-link;
    border-radius: 3px;
    padding: 2px 5px;
    margin-left: 12px;
    cursor: pointer;
  }
}

.announcement {
  @include text-bold;
  color: $logo;
  align-self: center;

  a {
    color: $logo;
  }
}

.signed-in-as-wrapper {
  font-size: 13px;
}

.avatar{
  cursor: pointer;
}

.signed-in-as{
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 25px;
  position: relative;
  max-width: 140px;
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &::after{
    position: absolute;
    display: block;
    height: 1px;
    content: ' ';
    background: $bg-light-70;
    width: 170px;
    left: -15px;
    bottom: -15px;
  }
}

.popover {
  width: 140px;
}

.popover-btn{
  width: 100%;
}

.popover-last-btn{
  margin-top: 5px;
}

.logo {
  align-self: center;

  > svg {
    width: 48px;
    height: auto;
  }
}

.logo-wrapper {
  display: flex;
}

.version {
  @include text-small;
  color: white;
  background: $bg-dark-70;
  padding: 3px 7px;
  border-radius: 2px;
  align-self: center;
  margin-left: 16px;
  line-height: 13px;

  &.upgrade {
    background: $warning;
  }
}

.upgrade-now {
  @include text-bold;
  color: $warning;
  align-self: center;
  margin-left: 7px;

  &:hover, &:global(.hover) {
    color: $warning;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  background: $header-background;
  padding: 0 24px;
}

.sider {
  background: $sider-background;
  padding-top: 18px;
  letter-spacing: 0.2px;
}

.base {
  position: relative;
}

.content-wrapper {
  background: white; 
}

.content {
  position: relative;
  background: white;
}

.favourite-route-option {
  font-size: 12px;
}

.favourite-route-overlay {
}

.favourite-route-select-mark-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  
  .favourite-route-select-mark {
    margin-top: 5px;
    @include text-small;
    color: white;
    line-height: 12px;
    padding: 0 8px;
    height: 26px;
  }
}