@import "../../global/styles";

.cluster-access-number {
  @include text-bold;
  color: $primary;
}

.cluster-error {
  color: $error;
  cursor: pointer;

  > :global(.anticon) {
    margin-right: 7px;
  }
}

.cluster-tooltip {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  max-height: 250px;
  overflow-y: auto;

  > a:last-child {
    margin-right: 0;
  }
}

.owner {
  @include clickable-name;
}

.actions {
  min-width: 100px !important;
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.setting-batch {
  @include ant-icon-button(transparent, white);
}

.delete {
  @include ant-icon-button($error);
  margin: -8px 0;
}

.delete-batch {
  @include ant-icon-button(transparent, white);
}

.reset-password {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

