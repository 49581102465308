@import '../../global/styles';

.changed {
  position: relative;
  > .autocomplete {
    border: 1px solid $warning;
  }

  > .text {
    @include text-small();
    color: $warning;

    cursor: pointer;
    position: absolute;
    right: 0;
    margin-top: -22px;
  }
}

.autocomplete {
  &[readonly] {
    background: $bg-light-70;
    color: $text-dark;
  }

  width: 100%;

  > div {
    width: 100%;
  }
}