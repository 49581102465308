.drawer {
  overflow: hidden;
  width: auto !important;

  > :global(.ant-drawer-mask) {
    display: none;
  }

  > :global(.ant-drawer-content-wrapper) {
    position: relative;
    margin-left: 45px;

    :global(.ant-drawer-body) {
      padding: 24px 24px 12px;
    }
  }

  &.no-padding {
    > :global(.ant-drawer-content-wrapper) {
      :global(.ant-drawer-body) {
        padding: 0;
      }
    }
  }
}
