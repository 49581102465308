@import "../../global/styles";

.actions {
  min-width: 100px !important;
}

.clickable {
  @include clickable-name;
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.setting-batch {
  @include ant-icon-button(transparent, white);
}

.delete {
  @include ant-icon-button($error);
  margin: -8px 0;
}

.delete-batch {
  @include ant-icon-button(transparent, white);
}

.reset-password {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.table {
  tbody > tr {
    .namespace {
      color: $text-dark-90;
    }

    &:hover, &:global(.hover) {
      .namespace {
        @include clickable-name-normal;
      }
    }
  }
}
