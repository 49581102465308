@import "../../global/styles";

.header {
  margin-top: -2px;
  margin-bottom: 16px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu {
    margin-left: -16px;
    margin-right: -16px;
    line-height: 38px;

    :global(.anticon) {
      margin-right: 6px;
    }
  }

  :global(.ant-menu-horizontal) > :global(.ant-menu-item)::after, :global(.ant-menu-horizontal) > :global(.ant-menu-submenu)::after {
    left: 0;
    right: 0;
  }

  :global(.ant-menu-item) :global(.ant-menu-item-icon), :global(.ant-menu-submenu-title) :global(.ant-menu-item-icon), :global(.ant-menu-item) :global(.anticon), :global(.ant-menu-submenu-title) :global(.anticon) {
    transition: unset;
  }
}

.name {
  @include h4;
  margin-left: 4px;
  margin-bottom: 14px;
}


