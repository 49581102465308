@import "../../global/styles";

.item {
  background: $primary;
  color: white;
  border-radius: 2px;
  white-space: nowrap;
  padding: 0 6px;
  height: 26px;
  line-height: 26px;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 10px;

  &:hover, &:global(.hover) {
    color: white;
  }
}