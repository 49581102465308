@import "../../../global/styles";

.profile-wrapper {
  padding: 16px 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.margin-bottom {
  margin-bottom: 13px;
}
