@import "../../../../global/styles";

.apps-list {
  margin-top: 20px;
  margin-bottom: 20px;
}

.nodes {
  padding-bottom: 10px;
  margin: 30px 0 16px;
  position: relative;

  &::after {
    height: 1px;
    position: absolute;
    left: -16px;
    right: -16px;
    bottom: 0;
    content: '';
    background-color: $text-light;
  }
}

.table {
  tbody > tr {
    &:hover, &:global(.hover) {
      .progress {
        :global(.ant-progress-inner) {
          background-color: white;
        }
      }
    }
  }
}