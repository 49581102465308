@import "../../../global/styles";

.clickable {
  @include clickable-name;
}

.actions {
  min-width: 100px !important;
}

.wakeup {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.setting-batch {
  @include ant-icon-button(transparent, white);
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.delete-batch {
  @include ant-icon-button(transparent, white);
}

.delete {
  @include ant-icon-button($error);
  margin: -8px 0;
}

.warning {
  color: $warning;
}

.sleeping {
  display: flex;
  align-items: center;
  color: $info;

  > span {
    margin-right: 4px;
    position: relative;
    top: -1px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.table {
  tbody > tr {
    .cluster {
      color: $text-dark-90;
    }

    &:hover, &:global(.hover) {
      .cluster {
        @include clickable-name-normal;
      }
    }
  }
}

.owner {
  @include text-small-bold();
  color: $text-dark-80;

  &.user {
    > :global(.anticon) {
      font-size: 12px;
      margin-right: 7px;
    }
  }

  &.team {
    > :global(.anticon) {
      font-size: 12px;
      margin-right: 7px;
    }
  }
}
