@import "../../../../global/styles";

.auth-error {
  padding-left: 20px;
  
  li {
    margin-top: 10px;
  }
  
  b {
    white-space: nowrap;
  }
}

.dashboard-select {
  padding-left: 15px;
}

.not-found {
  padding: 10px;
  color: $warning;
}

.select {
  > div {
    width: 600px;
  }
}

.grafana-wrapper {
  overflow: hidden;

  .grafana {
    margin-top: 15px;
    border: none;
    min-width: 1024px;
    min-height: 200px;
    width: 100%;
  }
}

.admin-wrapper {
  padding: 16px 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}