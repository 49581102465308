@import '~antd/dist/antd.css';
@import "src/global/xterm";

body {
  overflow-y: hidden;

  #root {
    display: flex;
    flex-direction: column;
    height: calc(100vh - (100vh - 100%));
    min-height: calc(100vh - (100vh - 100%));

    > * {
      min-height: 100%;
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
