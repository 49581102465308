.select-wrapper {
  display: flex;
  margin-right: 10px;
  
  &.active {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    
    > span:first-child {
      border-color: #40a9ff;
    }

    > div:last-child {
      > div > div {
        border-color: #40a9ff;
      }
    }
  }
  
  &:hover, &:global(.hover) {
    > span:first-child {
      border-color: #40a9ff;
    }

    > div:last-child {
      > div > div {
        border-color: #40a9ff;
      }
    }
  }
  
  > span:first-child {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    line-height: 30px;
    padding-left: 10px;
    padding-right: 3px;
    margin-right: -1px;
    border-left: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    z-index: 1;
    background: white;
    
    > svg {
      color: rgba(0,0,0,0.45);
      position: relative;
      top: 2px;
    }
  }
  
  > div:last-child {
    > div {
      > div {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        box-shadow: none !important;
      }
    }
  }
}