@import "../../../../global/styles";

.popover-table {
  display: block;
  width: 600px;
  
  i {
    color: $primary;
  }
  
  > div {
    display: flex;
    
    > div {
      padding-bottom: 5px;
      
      &:first-child {
        flex: 0;
        flex-basis: 200px;
        font-weight: bold;
      }
      
      &:last-child {
        flex: 1;
      }
    }
    
    &.status {
      font-weight: bold;
      padding-top: 5px;
      margin-top: 5px;
      border-top: 1px solid $primary;
    }
    
    &.header {
      font-style: italic;
      font-weight: bold;
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid $primary;
    }
  }
}

.wake-up {
  margin-top: 10px;
  text-align: center;
}

.sleeping-popover {
  > div:last-child {
    text-align: center;
  }
}

.sleeping-popover-info {
  
}

.sleeping {
  color: $info;
  white-space: nowrap;

  > span {
    margin-right: 4px;
    position: relative;
    top: -1px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
