@import "../../global/styles";

:global(.ant-modal-wrap) {
  overflow: hidden;
}

.modal.modal-component {
  > :global(.ant-modal-content) {
    background-clip: border-box;
    display: flex;
    flex-flow: column;

    > :global(.ant-modal-body) {
      @include text-bold();
      margin: 0;
      padding: 18px 24px 1px !important;
      max-height: calc(100vh - 280px);
      overflow-y: auto;
    }
  }

  :global(.ant-modal-header) {
    border-bottom: none;
    padding-bottom: 0;
    border-radius: 0;
  }
}

.modal {
  position: relative;
  
  :global(.anticon-exclamation-circle) {
    color: $text-dark-90 !important;
  }

  :global(.ant-modal-close) {
    display: block !important;
  }

  :global(.ant-modal-content) {
    border-radius: 0;

    :global(.ant-modal-confirm-title), :global(.ant-modal-title) {
      @include text-large-bold();
      position: relative;
      overflow: visible;
      padding-bottom: 19px;

      &::after {
        position: absolute;
        height: 1px;
        background: $bg-light-70;
        content: '';
        bottom: 0;
        left: -24px;
        right: -23px;
      }
    }

    :global(.ant-modal-confirm-btns) {
      display: none;
    }

    :global(.ant-modal-body) {
      padding: 18px 24px 0 !important;

      :global(.ant-modal-confirm-content) {
        @include text-bold();
        margin: 24px 0 0;
      }
    }

    :global(.ant-modal-footer) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .content {
    margin-bottom: 18px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
    
    > div:last-child {
      > button:last-child {
        margin-left: 10px;
      }
    }
  }
}

.delete-btn {
  @include ant-override-color-button($error, $error, $error);

  .delete-icon {
    position: relative;
    left: -3px;
    margin-right: 4px;
  }
}

.title {
  display: flex;
  justify-content: space-between;
}

.close {
  @include ant-icon-button($error);
  position: relative;
  top: -8px;
  z-index: 10;
}

.content {

}
