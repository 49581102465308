@import '../../../global/styles';

.fold-icon {
  margin-right: 5px;
}

.section {
  &:first-child {
    > .header {
        display: none;
    }

    > .content {
        border-bottom: none;
    }
  }

  > .header {
    @include text-large();
    color: $primary;

    padding-bottom: 10px;
    border-bottom: 1px solid $bg-light-60;

    &.clickable {
      cursor: pointer;
    }
  }

  > .content {
    padding-bottom: 8px;
    border-bottom: 1px solid $bg-light-60;
  }

  &.no-last-line {
    > .content {
      border-bottom: none;
    }
  }
}

