@import "../../../../../../global/styles";

.chart-values {
  display: flex;
  justify-content: space-between;
}

.section-expander {
  padding-top: 15px;
  border-top: 1px solid $primary;
  margin-top: 20px;
  display: flex;
}
