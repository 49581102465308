@import "../../../global/styles";

.not-found {
  padding: 10px;
  color: $warning;
}

.search {
  margin-right: 10px;
  width: 150px;
}

.not-found-wrapper {
  padding: 16px 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.selectors {
  display: flex;
  
  .select {
    min-width: 150px;
    > div {
      width: 150px;
    }
    
    margin-right: 10px;
  }
  
  .start-date {
    margin-right: 10px;
    width: 200px;
  }

  .end-date {
    width: 200px;
  }
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.table {
  tbody > tr {
    .link {
      color: $text-dark-90;
    }

    &:hover, &:global(.hover) {
      .link {
        @include clickable-name-normal;
      }
    }
  }
}