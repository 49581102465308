@import '../../../global/styles';

.step-2 {
  textarea {
    @include text;
    color: $text-dark-80;
    margin-bottom: 10px;
  }

  .option-a {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .or {
    position: relative;
    margin: 31px 0;

    > .text {
      z-index: 1;
      @include text-small-bold;
      color: $text-light-80;

      display: inline-block;
      margin-left: 10px;
      padding: 0 10px;
      background-color: white;
    }

    > .line {
      position: absolute;
      content: ' ';
      height: 2px;
      left: 0;
      right: 0;
      top: calc(50% - 2px/2);
      background: $text-light;
      z-index: -1;
    }
  }
}