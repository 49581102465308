.alert {
  margin-bottom: 10px;
}

.apps-drawer {
  overflow: visible !important;
  
  :global(.ant-drawer-content) {
    overflow: visible !important;
  }
}