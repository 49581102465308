@import "../../global/styles";

.loading {
  @include text();
  color: $loading;
}

.info {
  @include text();
  color: $success;
}

.error {
  @include text();
  color: $error;

  &.clickable {
    cursor: pointer;
  }

  :global(.ant-btn) {
    &:hover, &:global(.hover) {
      :global(span.anticon){
        svg {
          color: black;
        }
      }
    }
  }

  :global(span.anticon){
    margin-right: -10px;
    svg {
      color: $text-dark-80;
      width: 12px;
    }
  }
}
