@import "../../../../../global/styles";

.app-card {
  @include shadow-dark-10;
  border-radius: 5px;
  width: 140px;
  height: 110px;
  display: flex;
  flex-direction: column;
  transition: box-shadow .3s, opacity .3s;
  cursor: pointer;

  .app-card-icon-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    img {
      max-width: 130px;
      max-height: 65px;
    }

    > :global(.anticon) {
      svg {
        font-size: 70px;
        color: $bg-dark-70;
      }
    }

    .status {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      :global(.anticon) {
        visibility: hidden;
      }

      &.error {
        background: $error;
      }

      &.warning {
        background: $warning;
      }

      &.okay {
        background: $primary;
      }
    }
  }

  .app-card-title {
    line-height: 30px;
    flex-basis: 30px;
    height: 30px;
    background: $bg-dark-70;
    border-radius: 0 0 5px 5px;
    text-align: center;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 5px;
  }

  &.transparent {
    opacity: 0.4;

    &:hover, &:global(.hover) {
      opacity: 1;
    }
  }

  &:hover, &:global(.hover) {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

    .app-card-icon-wrapper {
      overflow: visible;

      .status {
        :global(.anticon) {
          visibility: visible;
        }
      }
    }
  }
}