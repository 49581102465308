@import "../../../../global/styles";

.no-metrics-api {
  color: $warning;
  padding-right: 10px;
  font-size: 18px;
}

.clickable {
  @include clickable-name;
}

.actions {
  min-width: 100px !important;
}

.wakeup-batch {
  @include ant-icon-button(transparent, white);
}

.wakeup {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.setting-batch {
  @include ant-icon-button(transparent, white);
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.delete-batch {
  @include ant-icon-button(transparent, white);
}

.delete {
  @include ant-icon-button($error);
  margin: -8px 0;
}

.warning {
  color: $warning;
}

.sleeping-popover {
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div:first-child {
    @include text-bold;
    text-align: center;
    color: $text-dark-90;
    margin-bottom: 16px;
  }

  > div:last-child {
    text-align: center;
  }
}

.sleeping-popover-info {
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    @include text-bold;
    text-align: center;
    color: $text-dark-90;
    margin-bottom: 16px;

    &:last-child {
      margin: 0;
    }
  }
}

.sleeping {
  color: $info;
  white-space: nowrap;

  > span {
    margin-right: 4px;
    position: relative;
    top: -1px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.upgrade-license-link {
  display: none;
  text-align: center;
  line-height: 90%;
}

table {
  tbody > tr {
    &:hover, &:global(.hover) {
      .upgrade-license-link {
        display: inline;
      }

      .hide-on-hover {
        display: none;
      }
    }
  }
}

.error-popover {
  max-width: 500px;
}

.table {
  tbody > tr {
    .cluster {
      color: $text-dark-90;
    }

    &:hover, &:global(.hover) {
      .cluster {
        @include clickable-name-normal;
      }

      .progress {
        :global(.ant-progress-inner) {
          background-color: white;
        }
      }
    }
  }
}

.owner {
  @include text-small-bold();
  color: $text-dark-80;

  &.user {
     > :global(.anticon) {
       font-size: 12px;
       margin-right: 7px;
     }
   }

  &.team {
    > :global(.anticon) {
      font-size: 12px;
      margin-right: 7px;
    }
  }
}

.out-of-sync-popover {
  display: flex;
  align-items: center;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 10px;
  }
}
