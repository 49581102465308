.row {
  display: flex;

  > div {
    &:first-child {
      flex: 1;
    }

    &:last-child {
      flex: 1;
      margin-left: 30px;
    }
  }
}