@import "../../global/styles";

.delete-batch {
  @include ant-icon-button(transparent, white);
}

.delete {
  @include ant-icon-button($error);
  margin: -8px 0;
}

.setting-batch {
  @include ant-icon-button(transparent, white);
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}