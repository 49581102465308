@import "../../../global/styles";

.admin-wrapper {
  padding: 16px 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.clickable {
  @include clickable-name;
}

.actions {
  min-width: 100px !important;
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.setting-batch {
  @include ant-icon-button(transparent, white);
}

.delete {
  @include ant-icon-button($error);
  margin: -8px 0;
}

.delete-batch {
  @include ant-icon-button(transparent, white);
}
