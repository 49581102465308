.checkbox {
    margin-top: 20px;
    
    .hours {
        width: 30px;
        font-size: 12px;
        padding: 2px 5px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
}

.info {
    margin-top: 10px;
}