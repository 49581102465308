@import "../../global/styles";

.item-drawer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 100%;

  .content {
    flex: 1;
    overflow-y: scroll;
    padding: 24px 24px 12px;
  }

  .buttons {
    flex: 0;
    flex-basis: 54px;
    min-height: 54px;
    padding-top: 11px;
    border-top: 1px solid $bg-light-70;
    display: flex;
    justify-content: center;

    > button {
      margin-right: 10px;
    }
  }
}
