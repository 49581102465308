@import "../../../../global/styles";

.clickable {
  @include clickable-name;
}

.actions {
  min-width: 100px !important;
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.setting-batch {
  @include ant-icon-button(transparent, white);
}

.delete-batch {
  @include ant-icon-button(transparent, white);
}

.error-status {
  color: $error;

  > :global(.anticon) {
    margin-right: 7px;
  }
}

.pending {
  color: $warning;
}