@import "../../global/styles";

.multi-item-tooltip {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  max-height: 250px;
  overflow-y: auto;

  > a:last-child {
    margin-right: 0;
  }
}

.multi-item-number {
  @include text-bold;
  color: $primary;
}