@import "../../global/styles";

.terminal {
  border-radius: 0 !important;
}

.dropdown {
  z-index: 99999;
  margin-right: 5px;
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.select-wrapper {
  display: flex;
  align-items: center;
  margin-left: 10px;

  > span:first-child {
    margin-right: 10px;
  }
}

.right-wrapper {
  margin-right: 10px;
}

.header {
  display: flex;
  justify-content: space-between;

  margin-bottom: 15px;
}

.hoverable {
  @include clickable-name;
}