@import '../../global/styles.scss';

.tabs {
  margin-top: 10px;

  > :global(.ant-tabs-bar) {
    margin: 0;
    border-bottom: 1px solid $text-light-90;

    :global(.ant-tabs-nav) {
      padding-left: 10px;

      :global(.ant-tabs-tab) {
        @include text;
        border-color: $text-light-90;
        color: $text-dark-80;
        background: $text-light;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:global(.ant-tabs-tab-active) {
          color: $primary;
          background: transparent;
          border-bottom: 1px solid white;
        }
      }
    }
  }
}