@import "../../../global/styles";

.admin-wrapper {
  padding: 16px 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.margin-top {
  margin-top: 10px;
}

.view-invoices {
  background-color: $warning;
  border: 1px solid $warning;
  color: white;

  &:hover, &:global(.hover) {
    background-color: $warning;
    border: 1px solid $warning;
    opacity: 0.8;
    color: white;
  }

  &:focus {
    background-color: $warning;
    border: 1px solid $warning;
    color: white;
  }

  margin-left: 10px;
}