.textarea {
  margin-bottom: 10px;
}

.manifest-url {
  margin-bottom: 10px;
}

.row {
  display: flex;

  > div {
    flex: 1;

    &:first-child {
      margin-right: 15px;
    }

    &:last-child {
      margin-left: 15px;
    }
  }
}