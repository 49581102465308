@import "../../../../../global/styles";


.user {
  width: 260px;
  margin-right: 10px;
  line-height: 30px;
  border-radius: 2px;

  > span {
    padding: 0 7px;
  }
}

.cluster-select-wrapper {
  display: flex;

  .select {
    flex: 1;
    > div {
      width: 100%;
    }
  }

  .plus-btn {
    flex: 0;
    flex-basis: 32px;
    @include ant-icon-button($success, $success);
  }
}

.rules-header {
  @include text-small-bold();

  padding-top: 15px;
  border-top: 1px solid $primary;
  margin-top: 20px;
  display: flex;

  > div {
    flex: 1;
    margin-right: 10px;

    &:nth-child(2) {
      margin-right: 0;
    }

    &:last-child {
      flex: 0;
      flex-basis: 32px;
      margin-right: 0;
    }
  }
}

.rules-wrapper {
  margin-bottom: 20px;

  > div {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.delete-btn {
  @include ant-icon-button($error, $error);
}

.rule-row {
  display: flex;

  .cluster {
    width: 100%;
    margin-right: 10px;
    background: $bg-light-70;
    line-height: 30px;
    height: 32px;
    border-radius: 2px;
    border: 1px solid $text-light-90;

    > span {
      padding: 0 7px;
    }
  }

  .select {
    > div {
      width: 100%;
    }
  }

  > div {
    flex: 1;

    &:last-child {
      flex: 0;
      flex-basis: 32px;
    }
  }
}
