@import "../../../../global/styles";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 16px;
  padding-bottom: 20px;

  > div {
    padding-top: 0;
  }

  &::after {
    height: 1px;
    position: absolute;
    left: -16px;
    right: -16px;
    bottom: 0;
    content: '';
    background-color: $text-light;
  }
}

.select {
  > div {
    width: 400px;
  }
}

.actions {
  min-width: 100px !important;
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.setting-batch {
  @include ant-icon-button(transparent, white);
}

.delete {
  @include ant-icon-button($error);
  margin: -8px 0;
}

.delete-batch {
  @include ant-icon-button(transparent, white);
}