@import "../../../global/styles";

.reset-password {
  text-align: center;
}

.password {
  display: flex;
  justify-content: space-between;
}

.sign-in-wrapper {
  @include auth-button-wrapper;
}

.legal-hint {
  @include text;
  padding-top: 10px;
  color: $text-dark-80;
  text-align: center;
}

.no-login {
  margin-top: 15px;
  @include text-bold;
  text-align: center;
}

.oidc-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > button {
    display: flex;
    justify-content: center;
    align-items: center;

    @include shadow-dark-10;
    @include text-bold;
    color: $text-dark-90;

    border-radius: 2px;
    border: none !important;
    width: 206px;
    height: 42px;

    :global(.anticon) {
      font-size: 20px;
      margin-right: 6px;
    }
  }
}

.or {
  position: relative;
  margin: 25px 0 10px;
  height: 22px;

  > .text {
    position: absolute;
    z-index: 1;
    @include text-small-bold;
    color: $text-light-80;

    display: inline-block;
    left: calc(50% - 19px);
    padding: 0 10px;
    background-color: white;
  }

  > .line {
    position: absolute;
    content: ' ';
    height: 2px;
    left: 0;
    right: 0;
    z-index: 0;
    top: calc(50% - 2px/2);
    background: $text-light;
  }
}