@import '../../../../../../global/styles';

.readme-wrapper {
  position: absolute;
  
  width: 650px;
  left: -650px;
  top: 0;
  bottom: 0;
  
  padding: 10px 20px;
  overflow: auto;
  
  background: white;
  border-right: 1px solid $bg-light-70;
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
  
  &.loading {
    padding-top: 30px;
  }
  
  .title {
    @include text-large-bold;

    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $bg-light-60;
  }
  
  .icon-header {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    
    .icon {
      border-radius: 3px;
      border: 1px solid $bg-light-70;
      margin-right: 10px;
      
      img {
        max-width: 200px;
        max-height: 150px;
      }

      > :global(.anticon) {
        svg {
          font-size: 150px;
          color: $bg-dark-70;
        }
      }
    }
    
    .description-wrapper {
      .description {
        @include text-small-bolder;
        margin-bottom: 15px;
      }
    }
    
    .type, .home {
      display: flex;
      margin-top: 10px;
      padding-bottom: 5px;
      border-bottom: 1px solid $bg-light-50;
      
      > div:first-child {
        @include text-bold;
        flex: 0 0 70px;
      }
      
      > div:last-child {
        flex: 1;
      }
    }
  }
}

.markdown {
  font-size: 12px;
  
  h1 {
    color: $bg-dark-80;
    font-size: 24px;
    border-bottom: 1px solid $bg-light-60;
    margin-top: 20px;
  }
  
  h2 {
    color: $bg-dark-80;
    font-size: 18px;
    border-bottom: 1px solid $bg-light-60;
    margin-top: 20px;
  }
  
  blockquote {
    border-left: 5px solid $warning;
    background: rgba($warning, 0.2);
    
    padding: 5px;
    padding-left: 10px;
    
    > p {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  
  pre {
    font-size: 11px;
    background: $bg-dark-80;
    color: $text-light-90;
    border-radius: 0px;
    padding: 8px;
    outline: 3px solid $bg-dark-80;
    
    > code {
      font-size: 11px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $bg-dark-80;
    }

    &::-webkit-scrollbar-thumb {
      background: $text-light-80;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      
    }
  }
  
  p code, table code, blockquote code {
    background: $bg-light-50;
    padding: 1px 2px;
    font-size: 11px;
  }
  
  table {
    table-layout: fixed;
    width: 100%;

    tr:nth-child(even) {
      background: #ECEFFF;
    }
    
    tr:nth-child(odd) {}
  }
}