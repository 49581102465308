@import "../../../global/styles";

.admin-wrapper {
  padding: 16px 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.margin-top {
  margin-top: 10px;
}
