@import "../../global/styles";

.label {
  @include text-small;
  color: $text-dark-90;
  padding-top: 10px;
  padding-bottom: 0.25em;

  &.bold {
    @include text-bolder;
    padding-top: 0;
  }
}