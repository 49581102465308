@import '../../../../../../global/styles';

.chart-select {
  .link-wrapper {
    display: none;
  }
}

.chart-select-label {
  display: flex;
  justify-content: space-between;
}

.link {
  color: $primary;
  cursor: pointer;
  padding: 2px 4px;
}