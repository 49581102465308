@import "../../../global/styles";

.first-last {
  display: flex;

  .spacer {
    width: 15px;
  }
}

.select {
  width: 100%;
}

.finish-wrapper {
  @include auth-button-wrapper;
}

.legal-hint {
  @include text;
  padding-top: 10px;
  color: $text-dark-80;
  text-align: center;
}
