@import '../../../global/styles';

.step-1 {
  .cluster-name {
    max-width: 500px;
  }
}

.next {
  margin-top: 10px;
}
